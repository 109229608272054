import React from 'react';

export const Feedback: React.FC = () => {
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <iframe
                style={{ width: '100%', height: '100%' }}
                src="https://docs.google.com/forms/d/e/1FAIpQLScEwTolDvt96eW1eUlAmcWBLYxDLTh75vqAN_2hQ1O4mOjNsQ/viewform"
            />
        </div>
    );
};
