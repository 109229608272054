// Color palette https://coolors.co/605ca8-51a3a3-f79824-a22c29-04724d
export const WHITE = '#FFFFFF';
export const LIGHT_GREY = '#e2e2e2';
export const GREY = '#7a7a7a';
export const DARK_GREY = '#414141';
export const BLACK = '#000000';

export const LIGHT_PURPLE = '#9772a7';
export const PURPLE = '#4c1f56';
export const LIGHT_BLUE = '#68a7e3';
export const BLUE = '#3C91E6';
export const LIGHT_ORANGE = '#f6c588';
export const ORANGE = '#f79824ff';
export const LIGHT_RED = '#D65E5C';
export const RED = '#AD2102';
export const LIGHT_GREEN = '#9fcb42';
export const GREEN = '#7FB800';
export const HINT_GREY = '#434343';

export const GREEK_BLUE = '#F0F5FF';
export const HIGHLIGHT_BLUE = '#0050b3';
export const DARK_BLUE = '#096DD9';
